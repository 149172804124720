.tabBarWrapper {
    display: flex;
    align-items: center;
    border-top: 1px solid rgb(235, 235, 235);
    position: fixed;
    bottom: -60px;
    height: 125px;
    left: 0px;
    right: 0px;
    padding-bottom: 60px;
    contain: paint;
    background: rgb(255, 255, 255);
    transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, visibility 0.2s ease 0s;
    z-index: 1000;
    transform: translateY(0);
}
.tabBarWrapperHidden {
    transform: translateY(100%);
}

.tabBar {
    flex: 1 0 auto;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 560px;

}

.tabLink {
    text-decoration: none;
    color: var(--text-main);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.625rem;
    line-height: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    font-weight: 600;
}
.tabLink {
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    border: 0px;
    max-width: 20%;
    min-width: 0px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-wrap: break-word;
    hyphens: auto;
    padding: 0px 2px;
}
.tabLink img {
    display: block;
    fill: none;
    height: 24px;
    width: 24px;
    stroke: currentcolor;
    stroke-width: 2;
    overflow: visible;
    margin-bottom: 6px;
}


@media screen and (min-width: 744px) {
    .tabBarWrapper {
        display: none;
    }
}
